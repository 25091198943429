<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.service"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="large"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
						  <el-col :span="12">
								<el-form-item  :label="$t('message.dealType')" prop="deal_types" size="large">
									<el-select class="w-100" v-model="form.deal_types" :placeholder="$t('message.dealType')" multiple>
										<el-option :label="$t('message.commertial_cargo')" :value="'commercial'"></el-option>
										<el-option :label="$t('message.parcel')" :value="'parcel'"></el-option>
								</el-select>
								</el-form-item>
							</el-col>
                    <!-- end-col -->
                </el-row>
					 <el-row :gutter="20" style="margin-top: 20px">
							<div class="radio">
								<el-radio v-model="form.radio" :label="true" :value="true" border size="medium">{{ $t('message.total_price') }}</el-radio>
								<el-radio v-model="form.radio" :label="false" :value="false" border size="medium">{{ $t('message.percent') }}</el-radio>
							</div>
						   <div class="total_price">
								<div v-if="form.radio == true" class="price">
									<el-col :span="12">
										<el-form-item :label="$t('message.total_price')">
											<crm-money-input v-model="form.service_sum" :old="form.service_sum" :size="'large'"></crm-money-input>
										</el-form-item>   
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('message.currency')">
											<select-currency v-model="form.currency_id" :id="form.currency_id" :size="'large'"></select-currency> 
										</el-form-item>     
                    			</el-col>
								</div>
								<div v-else class="percent">
									<el-col :span="15">
										<el-form-item :label="$t('message.percent')">
											<el-input 
												:placeholder="$t('message.percent')" 
												v-model="form.percent" 
												size="large"
												@input="allNumeric"
												>
												<template slot="append"><b class="percent-slot">%</b></template>
											</el-input>
                      			</el-form-item>   
									</el-col>  
								</div>
						</div>
					 </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from '@/components/selects/select-currency';
export default {
  mixins: [form, drawer],
  components: {selectCurrency},
  data() {
    return {
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "services/rules",
          model: "services/model",
          columns: "services/columns",
      }),
  },
   watch:{
		'form.radio': {
			handler: function (newVal){
				if (newVal) this.form.percent = null;
				else {
					this.form.service_sum = 0;
					this.form.currency_id = null;
				}
			}
		}
   },
  methods: {
      ...mapActions({
          save: "services/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
		allNumeric: function(num){
			if(num.match(/^-?\d+$/)){
				if (num > 100) this.form.percent = null;	
				else this.form.percent = num;
			}
			else this.form.percent = null;
		},
		afterLeave(){
			this.$store.commit('services/EMPTY_MODEL');
		} 
  },
};
</script>
<style>
.radio{
	width: 100%;
	margin-left: 12px;
}
.total_price{
	width: 100%;
	display: flex;
	flex-direction: row;
	margin: 30px 0 0 12px;
}
.price{
	width: 100%;
}
.percent{
	width: 50%;
}
.percent-slot{
	font-weight: bold;
	font-size: 17px;
	color: black;
}
</style>