<template>
  <div class="">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.services") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

				   <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.percent.show">
                {{ columns.percent.title }}
              </th>

				  <th v-if="columns.total_price.show">
                {{ columns.total_price.title }}
              </th>

				  <th v-if="columns.currency.show">
                {{ columns.currency.title }}
              </th>

				   <th v-if="columns.deal_types.show">
                {{ columns.deal_types.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

				  
				   <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>
				  
				  <th v-if="columns.percent.show">
                <crm-input
                  :placeholder="columns.percent.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.percent"
						disabled
                ></crm-input>
              </th>

				    <th v-if="columns.total_price.show">
                <crm-input
                  :placeholder="columns.total_price.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.total_price"
						disabled
                ></crm-input>
              </th>

				   <th v-if="columns.currency.show">
						<select-currency 
							v-model="filterForm.currency_id" 
							:id="filterForm.currency_id"
							:class="mode ? 'filter__day' : 'filter__night'"
						></select-currency>
                <!-- <crm-input
                  :placeholder="columns.currency.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.currency"
						disabled
                ></crm-input> -->
              </th>

				   <th v-if="columns.deal_types.show">
						<crm-input
							:placeholder="columns.deal_types.title"
							:class="mode ? 'filter__day' : 'filter__night'"
							v-model="filterForm.name"
							disabled
						></crm-input>
					</th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="service in list"
              :key="service.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">
                {{ service.id }}
              </td>

              <td v-if="columns.name.show">
                {{ service.name }}
              </td>

				   <td v-if="columns.percent.show">
                {{ service.percent }}
              </td>

				  <td v-if="columns.total_price.show">
                {{ service.service_sum | formatNumber() }}
              </td>

				   <td v-if="columns.currency.show">
                {{ service.currency ? service.currency.symbol : '' }}
              </td>

				   <td v-if="columns.deal_types.show">
						<div v-for="(item, index) in service.items" :key="index">
							<el-tag v-if="item.deal_type == 'commercial'">{{ $t('message.commertial_cargo') }} <br></el-tag>
							<el-tag type="warning" v-else>{{ $t('message.parcel') }}</el-tag>
						</div>
					 <!-- <span @click="showItems()" style="cursor: pointer; color: #3490dc;">{{ $t('message.show')}} <i class="el-icon-view"></i></span> -->
               </td>

              <td v-if="columns.created_at.show">
                {{ service.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ service.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="service"
                  :actions="actions"
                  :permissionShow="'services.update'"
                  :permissionDestroy="'services.delete'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions, mapState } from "vuex";
import SelectCurrency from '../../components/selects/select-currency';

export default {
  name: "services",
  mixins: [list],
  components: {
    Pagination,
    CrmCreate,
    CrmUpdate,
	 SelectCurrency
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      list: "services/list",
      columns: "services/columns",
      pagination: "services/pagination",
      filter: "services/filter",
      sort: "services/sort",
      mode: "MODE",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "services/index",
      setPagination: "services/setPagination",
      updateSort: "services/updateSort",
      updateFilter: "services/updateFilter",
      updateColumn: "services/updateColumn",
      updatePagination: "services/updatePagination",
      show: "services/show",
      empty: "services/empty",
      delete: "services/destroy",
      refreshData: "services/refreshData",
    }),
	 showItems(){},
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("services/EMPTY_LIST");
    next();
  },
};
</script>
<style>
.link{
	text-decoration: none;
}
</style>

